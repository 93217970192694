var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c(
            "fb-button",
            {
              attrs: { slot: "right", size: "small", type: "primary" },
              on: { click: _vm.saveBasicInfo },
              slot: "right",
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fb-summary-detail" },
        [
          _c("p", { staticClass: "detail-entrance" }, [
            _c("label", { staticClass: "label-entrance" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _vm._l(_vm.basicInfo, function (detailValue, detailKey) {
            return _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: Object.keys(_vm.detailChineseMap).includes(
                      detailKey
                    ),
                    expression:
                      "Object.keys(detailChineseMap).includes(detailKey)",
                  },
                ],
                key: detailKey,
                staticClass: "detail-key-value",
              },
              [
                _c("label", { staticClass: "label-key" }, [
                  _vm._v(_vm._s(_vm.detailChineseMap[detailKey])),
                ]),
                !_vm.isEditedInfos.includes(detailKey)
                  ? _c("label", { staticClass: "label-value" }, [
                      _c("input", {
                        staticClass: "input-value",
                        attrs: {
                          type: "text",
                          placeholder: _vm.basicInfo[detailKey],
                        },
                      }),
                    ])
                  : _c("label", { staticClass: "label-value" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.basicInfo[detailKey],
                            expression: "basicInfo[detailKey]",
                          },
                        ],
                        staticClass: "input-value",
                        attrs: { type: "text" },
                        domProps: { value: _vm.basicInfo[detailKey] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.basicInfo,
                              detailKey,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }