<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <fb-button slot="right"
                 size="small"
                 type="primary"
                 @click="saveBasicInfo">
        保存
      </fb-button>
    </fb-header>
    <div class="fb-summary-detail">
      <p class="detail-entrance">
        <label class="label-entrance">{{ title }}</label>
        <!-- <i class="iconfont icon-child-off"></i> -->
      </p>
      <p class="detail-key-value"
         v-for="(detailValue, detailKey) in basicInfo"
         :key="detailKey"
         v-show="Object.keys(detailChineseMap).includes(detailKey)">
        <label class="label-key">{{ detailChineseMap[detailKey] }}</label>
        <label class="label-value"
               v-if="!isEditedInfos.includes(detailKey)">
          <input type="text"
                 class="input-value"
                 :placeholder="basicInfo[detailKey]">
        </label>
        <label class="label-value"
               v-else>
          <input type="text"
                 class="input-value"
                 v-model="basicInfo[detailKey]">
          <!-- <i class="iconfont icon-child-off"></i> -->
        </label>
      </p>
    </div>
  </fb-page>
</template>

<script>
import { identity } from './js/type'
import { caseBase } from '~api-config'
export default {
  name: 'summary-detail',
  data () {
    return {
      title: '基本信息',
      isEditedInfos: ['acceptCaseTime', 'caseNo', 'password'],
      basicInfo: {},
      caseId: 0,
      detailChineseMap: {
        caseReasonName: '案由',
        acceptCaseTime: '受案时间',
        caseNo: '案号',
        password: '密码',
        agencyIdentity: '代理身份',
        agencyAuthority: '代理权限'
      }
    }
  },
  created () {
    this.basicInfo = {
      ...this.$route.params.basicInfo,
      agencyAuthority: this.$route.params.basicInfo.agencyAuthority.map(x => {
        if (x === 'COMMONLY_AGENT') x = '一般授权'
        if (x === 'SPECIAL_AGENT') x = '特别授权'
        return x
      }),
      agencyIdentity: this.$route.params.basicInfo.agencyIdentity.map(x => {
        return identity(x)
      })
    }
    this.caseId = this.$route.params.caseId
    this.agencyInfId = this.$route.params.agencyInfId
  },
  methods: {
    saveBasicInfo () {
      this.$axios
        .put(caseBase + '/lts/inf/updateBasicInf', {
          acceptCaseTime: new Date(this.basicInfo.acceptCaseTime).getTime(),
          caseNo: this.basicInfo.caseNo,
          password: this.basicInfo.password,
          caseId: this.caseId,
          agencyInfId: this.agencyInfId
        })
        .then(res => {
          if (res.data.code === '200') {
            this.$router.back()
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.fb-summary-detail
  text-align center
  width 90%
  margin 10px auto
  border-radius 5px
  background white
  font-size 15px
  box-shadow 0px 0px 0px 1px #DCF0FA
  .detail-entrance
    color #169BD5
    overflow hidden
    height 40px
    .label-entrance
      float left
      line-height 40px
      margin-left 10px
    .iconfont
      float right
      line-height 40px
      margin-right 10px
  .detail-key-value
    overflow hidden
    height 40px
    border-top 1px solid #80808024
    .label-key
      float left
      line-height 40px
      margin-left 10px
    .label-value
      float right
      line-height 40px
      margin-right 10px
      .input-value
        border none
        text-align right
      .iconfont
        color #169BD5
</style>
