// 案件的身份判别
export const identity = type => {
  switch (type) {
    case 'PLAINTIFF':
      return '原告'
    case 'DEFENDANT':
      return '被告'
    case 'THIRD_PARTY':
      return '第三人'
    case 'NOT_INVOLVED_CASE':
      return '案外人'
    case 'APPELLANT':
      return '上诉人'
    case 'APPELLEE':
      return '被上诉人'
    case 'FIRST_TRIAL_PLAINTIFF':
      return '原审原告'
    case 'FIRST_TRIAL_THIRD_PARTY':
      return '原审第三人'
    case 'FIRST_TRIAL_DEFENDANT':
      return '原审被告'
    case 'GRIEVANT':
      return '申诉人'
    case 'RESPONDENT':
      return '被申诉人'
    case 'APPLICANT':
      return '申请人'
    case 'CLAIMANT':
      return '被申请人'
    case 'DEFENDANT_PEOPLE':
      return '被告人'
    case 'CRIMINAL_SUSPECT':
      return '嫌疑人'
    case 'DRIVER':
      return '驾驶人'
    case 'CAR_OWNER':
      return '车主'
    case 'VICTIM':
      return '受害人'
    case 'INSURANCE_COMPANY':
      return '保险公司'
    case 'PASSENGER':
      return '乘车人'
    case 'EXECUTION_APPLICANT':
      return '申请执行人'
    case 'EXECUTEE':
      return '被执行人'
    default:
      return '未匹配到'
  }
}
